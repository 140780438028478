import * as axios from "axios";
import { create, update } from "lodash";


export default class Api {
    static api_token;
    static api_url   = "https://api.maprota.com/api/"
  //  static api_url   = "https://localhost:32768/api/"
    static headers = {};

    static instance = axios.create({
        baseURL: Api.api_url,
        timeout: 60000,
    });

    static roles = {
        getRoles: () => {
            return Api.instance.get('Rolles', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static companies = {
        getCompanies: () => {
            return Api.instance.get('Companies', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
    }
    };

    static departments = {
        getDepartments: () => {
            return Api.instance.get('Departments', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static projects = {
        getProjects : () => {
            return Api.instance.get('Projects', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getProjectByKey : (id) => {
            return Api.instance.get(`Projects/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        createProject : (data) => {
            return Api.instance.post(`Projects`,
                data,
                {headers: {'Authorization': Api.api_token}
            })
        },

        updateProject: (id, data) => {
            return Api.instance.put(`Projects/${id}`,
                data,
                {headers: {'Authorization': Api.api_token}}
                )
        },

        deleteProject : (id) => {
            return Api.instance.delete(`Projects/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static tasks = {
        getTasks : () => {
            return Api.instance.get('Tasks', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getTaskByKey : (id) => {
            return Api.instance.get(`Tasks/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        createTask : (data) => {
            return Api.instance.post(`Tasks`,
                data,
                {headers: {'Authorization': Api.api_token}
                })
        },

        updateTask: (id, data) => {
            return Api.instance.put(`Tasks/${id}`,
                data,
                {headers: {'Authorization': Api.api_token}}
            )
        },

        deleteTask : (id) => {
            return Api.instance.delete(`Tasks/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };
    static SKILL_PREFIX = "Skills";

    static skills = {

        getMySkills : () => {
            return Api.instance.get(`${this.SKILL_PREFIX}/GetMeSkills`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getMySkillByID : (name) => {
            return Api.instance.get(`${this.SKILL_PREFIX}/GetMeSkill/${name}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getSkillSuggestions : (filter) => {
            return Api.instance.get(`${this.SKILL_PREFIX}/SuggestionList/${filter}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        findPersonBySkill : (name) => {
            return Api.instance.get(`${this.SKILL_PREFIX}/GetPersonwithSkill/${name}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        addMySkill : (data) => {
            return Api.instance.post(`${this.SKILL_PREFIX}/Post`,
                data,
                {headers: {'Authorization': Api.api_token}
                })
        },

        deleteMySkill : (name) => {
            return Api.instance.delete(`${this.SKILL_PREFIX}/Delete/${name}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static qualifications = {

        getQualificationsBySkill : (SkillName) => {
            return Api.instance.get(`${this.SKILL_PREFIX}/Qualification`, {
                params: {
                    SkillName
                },
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        addNewQualificationForSkill : (SkillName, qualification) => {
            return Api.instance.post(`${this.SKILL_PREFIX}/Qualification`, qualification, {
                params: {
                    SkillName
                },
                headers: {
                    'Authorization': Api.api_token
                }
            })

        },

        deleteMySkill : (name) => {
            return Api.instance.delete(`${this.SKILL_PREFIX}/Delete/${name}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static meetings = {


        getMeetings : () => {
            // return Api.instance.get('Tasks', {
            //     headers: {
            //         'Authorization': Api.api_token
            //     }
            // })
        },

        getMeetingByKey : (id) => {
            // return Api.instance.get(`Tasks/${id}`, {
            //     headers: {
            //         'Authorization': Api.api_token
            //     }
            // })
        },

        createMeeting : (data) => {
            return Api.instance.post(`Meetings`,
                data,
                {headers: {'Authorization': Api.api_token}
                })
        },

        updateMeeting: (id, data) => {
            // return Api.instance.put(`Tasks/${id}`,
            //     data,
            //     {headers: {'Authorization': Api.api_token}}
            // )
        },

        deleteMeeting : (id) => {
            // return Api.instance.delete(`Tasks/${id}`, {
            //     headers: {
            //         'Authorization': Api.api_token
            //     }
            // })
        }
    };

    static auth = {
        createAccount(params){
            return Api.instance.post(`Auth/register`, params, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },
        verifyUser(params){
        const result = Api.instance.post(`Auth/Mailadressvalidate?Key=${params.Key}&PeopleID=${params.People}`,
            params,
            {
                headers:
                    {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
            }
        )
        return result;
        },
        // verifyUser(params){
        //     return Api.instance.post(`Auth/Mailadressvalidate`, {}, {
        //         params: {
        //             ...params
        //         }
        //     })
        // },

        resetPassword(email){
            return Api.instance.post(`Auth/PasswortReset?Mailadresse=${email}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        changePassword(params){
            return Api.instance.post(`Auth/PasswortResetSetNew?PeopleID=${params.PeopleID}&EMail_validate_Key=${params.EMail_validate_Key}&NewPassword=${params.Password}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },
    }

    static mail = {
        getMailFolders(mailSettingID){
            return Api.instance.get(`Mails/GetRootFolder?MailSettingID=${mailSettingID}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getMails(mailSettingID, folderID){

            let url = `Mails/Get/?ID_MailSetting=${mailSettingID}`
            if(folderID) url += `&FolderID=${folderID}`
            return Api.instance.get(url, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        sendMails(mailSettingID, emailData){
            return Api.instance.post(`Mails/Post?ID_MailSetting=${mailSettingID}`, emailData, {
                headers: {
                    'Authorization': Api.api_token,
                    'Content-Type': 'application/json'
                }
            })
        }
    }


    


    static people = {
        getPeople: () => {
            const people = Api.instance.get('People/People', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return people
        },

        updateMe: (myID ) => {
            const people = Api.instance.get(`/People/me/${myID}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return people
        },

        updateContact: (id ) => {
            const people = Api.instance.get(`People/Person${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return people
        },

        getPersonById: (id) => {
            const person = Api.instance.get(`People/Person/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return person
        },

        // updateTask: (id, data) => {
        //     return Api.instance.put(`Tasks/${id}`,
        //         data,
        //         {headers: {'Authorization': Api.api_token}}
        //     )
        // },

        updateMyContact: (id, data) => {
            return Api.instance.put(`People/Person/${id}`,
                data,
                {headers: {'Authorization': Api.api_token}}
            )
        },

        insertToNetwork: (item) => {
            return Api.instance.post('/People/Me/Network/Request', item, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        deleteFromNetwork: (personID, relationshipID) => {
            return Api.instance.delete(`/People/Me/Network/${personID},${relationshipID}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getMyNetwork: () => {
            return Api.instance.get('/People/Me/Network', {
                headers: {
                    'Authorization': Api.api_token
                }
                })
        },

        getMyRequests: () => {
            return Api.instance.get('/People/Me/Network/Request', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        getMyReceived: () => {
            return Api.instance.get('/People/Me/Network/Received', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        deleteRequest: (otherID) => {
            return Api.instance.delete(`/People/Me/Network/Request/${otherID}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    }

    static mailOptions = {
        getMailOptions: () => {
            const mails = Api.instance.get('Mails/GetMailSetting', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return mails
        },

        getMailOptionsByKey: (id) => {
            return Api.instance.get(`Mails/GetMailSetting/${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },

        createMailOptions: (data) => {
            return Api.instance.post(`Mails/addMailSetting`,
                data,
                {
                    headers: { 'Authorization': Api.api_token }
                })
        },

        updateMailOptions: (id, data) => {
            console.log("Update:");
            console.log("ID:"+id);
            return Api.instance.put(`Mails/PutMailSetting?ID=${id}`,
                data,
                { headers: { 'Authorization': Api.api_token } }
            )
        },

        deleteMailOptions: (id) => {
            return Api.instance.delete('Mails/DeleteMailSetting?ID=${id}', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };

    static OptionClient = {
        getOptions: () => {
            const lists = Api.instance.get('OptionClient', {
                headers: {
                    'Authorization': Api.api_token
                }
            })
            return lists
        },
        createOption: (data) => {
            return Api.instance.post(`OptionClient`,
                data,
                {headers: {'Authorization': Api.api_token}
                })
        },
        updateOption: (name, data) => {
            return Api.instance.put(`OptionClient/${name}`,
                data,
                {headers: {'Authorization': Api.api_token}}
            )
        },
        deleteOption: (name) => {
            return Api.instance.delete(`OptionClient/${name}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        },
    };

    static Dokuments = {
        getDokuments: async (id) => {
            const response = await Api.instance.get(`Documents/?id=${id}`, {
                responseType: 'blob',
                headers: {
                    'Authorization': Api.api_token
                }
            });
            return response.data;
        },
        createDokument : (data, file) => {
            const form = new FormData();
            form.append("file", file);

            return Api.instance.post(`Documents?archiveBusinessObjektType=${data.ObjektType}&BusinessObjektID=${data.ObjektID}`,
            form,
                {
                    headers: {
                        'Authorization': Api.api_token,
                        'Content-Type': 'multipart/form-data'  
                    }
                })
        },
        deleteDokument : (id) => {  
            return Api.instance.delete(`Documents/?id=${id}`, {
                headers: {
                    'Authorization': Api.api_token
                }
            })
        }
    };


    static init = (token) => {
        if (token) {
            Api.api_token = `Bearer ` + token;
        }

    };

    static clear = () => {
            Api.api_token = undefined;
    };

    static login(params) {

        const result = Api.instance.post(
            'Auth/login',
            params,
            {
                headers:
                    {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
            }
        )
        return result
    }

    static getMe() {
        return Api.instance.get('People/Me', {
            headers: {
                'Authorization': Api.api_token
            }
        })
    }

    static getLanguages() {
        return Api.instance.get('Languages')
    }

    static updateUser(newUser) {
        const id = newUser.id ? newUser.id : 0;
        return Api.instance.put(
            `People/Me/${id}`,
            newUser,
            {
                headers: {
                    'Authorization': Api.api_token
                }
            })
    }

    static handleErrors(response) {
        const code = response.status;
        if (code === 200 || code === 201 ) {
            return response;
        }else {
            throw Error(response.statusText);
        }
    }


}
