import React, { useState } from "react";
import Aufgabenliste from "../Aufgabenliste/Aufgabenliste";
import AufgabenGant  from "../AufgabenGant/AufgabenGant";
import DropDownButton from 'devextreme-react/drop-down-button';
import { useHistory } from "react-router-dom";

export default function () {
    const Ansichten = ["Liste", "Kanban"];
    const [SelectedView,SetSelectedView] = useState("Liste");
    const history = useHistory();

    function onViewSelected(e){
     SetSelectedView(e.item);
     if (e.item === "Kanban"){
        history.push("/Aufgabenliste/AufgabenKanban");
     }else{
        history.push("/Aufgabenliste");
     }
    };

    return (
        <React.Fragment>
        {/* <DropDownButton
                text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                icon="table"
                items={Ansichten}
                onSelectionChanged={onViewSelected}
              />
              <br/> */}
        {SelectedView == "Liste" ? 
        <Aufgabenliste Ansichten={Ansichten} SelectedView={SelectedView} onViewSelected={onViewSelected}/> 
        :
        <AufgabenGant /> }
        </React.Fragment>
    );
}