import React, { useState,useEffect,useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {Column, ColumnChooser, DataGrid, GroupPanel, Editing,  HeaderFilter, StateStoring} from 'devextreme-react/data-grid';
import { Button, TextBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Link} from 'react-router-dom'
import { useAuth } from '../../contexts/auth';
import i18n from "i18next";
import Api from "../../utils/api";
import './Aufgabenliste.scss';
import { ScrollView } from 'devextreme-react/scroll-view';
import ListMobile from './MobileAnsicht/ListeMobile';
import { Modal, Button as MantineButton, Input, Divider } from '@mantine/core';
import { CiWarning } from "react-icons/ci";
import { IoFilterOutline } from "react-icons/io5";
import { Alert } from '@mantine/core';
import AufgabenlistePC from './PCAnsicht/AufgabenlistePC';
import FilterPopup from './PCAnsicht/FilterPopup';
import { set } from 'lodash';
import DropDownButton from 'devextreme-react/drop-down-button';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);

    }

    return response;
}

function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
};


function getUser() {
    return Api.getMe()
        .then(response => response.data)
}

export default function (onViewSelected, SelectedView, Ansichten) {

    const t = i18n.getFixedT(null, 'taskList');
    const { recoveryCode } = useParams();
    const history = useHistory();
    const [isModalOpen, setModalOpen] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [user, setUser] = useState(null);
    const [filter, setFilter] = useState([]);

    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [dataArray, setDataArray] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [aktualisieren, setAktualisieren] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState('');
    const [aktuelleFilterBedingung, setAktuelleFilterBedingung] = useState(null);
    const [filterObject, setFilterObject] = useState(null); //State für die Gefilterten Aufgaben aus dem THEAD der Tabelle.
    const [filterBedingungAusEigenerTabelle, setFilterBedingungAusEigenerTabelle] = useState(null); //State für die Filterbedingung auf der aktuellen Seite (Aufgabenfavoriten).
    const [bedingungenFürPopup, setBedingungenFürPopup] = useState(null);
    const location = useLocation();
    const [ausgewählteSpalten, setAusgewählteSpalten] = useState([]);

    useEffect(() => {
      console.log("Location", location);
      if (location.pathname === "/Aufgabenliste") {
        console.log("Location ist Aufgabenliste");
        setFilterBedingungAusEigenerTabelle(null);
      }
    }, [location]);

    useEffect(() => {
        Api.getMe().then(response => {
            const id = response.data.id;
            Api.OptionClient.getOptions().then(response => {
                const data = response.data;
                const Aufgabenfilter = data.filter((option) => option.name === `Aufgabenfav_${id}`);
                if (Aufgabenfilter.length !== 0) {
                  let AlleFilter = JSON.parse(Aufgabenfilter[0].option);
                  AlleFilter = AlleFilter.map(item => {
                    if (typeof item === 'string') {
                      try {
                        return JSON.parse(item);
                      } catch {
                        return item;
                      }
                    }
                    return item;
                  });
                  setFilter(AlleFilter);
                }
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        Api.tasks.getTasks().then(response => {
            const data = response.data;
      
            if (data.length === 0) {
              setNoDataMessage(t('noData'));
            } else {
              const formattedData = data.map(item => {
                return {
                  ...item,
                  start: item.start ? new Date(item.start).toISOString().split('T')[0] : null,
                  end: item.end ? new Date(item.end).toISOString().split('T')[0] : null,
                  follow_up: item.follow_up ? new Date(item.follow_up).toISOString().split('T')[0] : null,
                }
              });
              setDataArray(formattedData);
              setFilteredData(formattedData);
            }
            }).catch(error => {
            console.error(error);
            });
    }, [aktualisieren]);

    useEffect(() => { //Hier wird die aktuelle Filterung in das FilterPopup übergeben.
      console.log("FilterBedingungAusEigenerTabelle: ", filterBedingungAusEigenerTabelle);
      if (filterBedingungAusEigenerTabelle !== undefined && filterBedingungAusEigenerTabelle !== null) {
        setAktuelleFilterBedingung(filterBedingungAusEigenerTabelle);
      } else if (filterBedingungAusEigenerTabelle === undefined || filterBedingungAusEigenerTabelle === null) { //Wenn der Filter aus der Tabelle gelöscht wird, soll auch der Filter im Popup gelöscht werden.
        setAktuelleFilterBedingung(null);
      }
    }, [filterBedingungAusEigenerTabelle]);

    const onSelectionChanged = useCallback( (e) => {

        const data = e.selectedRowsData[0];
        console.log('Zeile ausgewählt' + data.id);
        console.log(Object.values(data));

        history.push({
            pathname: `/Aufgabe/${data.id}`,
        });
        //history.push('/aufgabe');
    }, [history, recoveryCode]);

    const customDataSource = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.tasks.getTasks()
                .then(response => response.data);

        },
        remove: (key) => {
            Api.tasks.deleteTask(key.id)
                .then(handleErrors)
                .catch(() => { throw 'Network error' });
        },
        byKey: (key) => {
            return Api.tasks.getTaskByKey(key)
                .then(response => response.data);

        },
        insert: (values) => {
            return Api.tasks.createTask(values)
                .then(handleErrors)
                .catch(() => { throw 'Network error' });
        },
        update: (key, values) => {
            Api.tasks.updateTask(key, values)
                .then(handleErrors)
                .catch(() => { throw 'Network error' });
        }
    });
    const formatDate = new Intl.DateTimeFormat('en-US').format;
    function DataRow(rowInfo) {
        
        return  <React.Fragment>
            <tr className="main-row">
              <td rowSpan="2"><img src={rowInfo.data.Picture} /></td>
              <td>{rowInfo.data.Prefix}</td>
              <td>{rowInfo.data.FirstName}</td>
              <td>{rowInfo.data.LastName}</td>
              <td>{rowInfo.data.Position}</td>
              <td>{formatDate(new Date(rowInfo.data.BirthDate))}</td>
              <td>{formatDate(new Date(rowInfo.data.HireDate))}</td>
            </tr>
            <tr className="notes-row">
              <td colSpan="6"><div>{rowInfo.data.Notes}</div></td>
            </tr>
          </React.Fragment>
        

         };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
    const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
    };
         
    window.addEventListener('resize', handleResize);
         
    // Cleanup function
    return () => {
    window.removeEventListener('resize', handleResize);
    };
    }, []);

    useEffect(() => {
        getUser().then(user => {
            setUser(user);
            {/* if(user.first_Name === null && user.last_Name === null) { */}
            if(user.first_Name === null || user.first_Name === '' && user.last_Name === null || user.last_Name === '') {
    
                console.log('first_Name und last_Name sind null');
                setModalOpen(true);
            }
        }).catch(error => {
            console.error('Fehler beim Abrufen des Benutzers:', error);
        });
    }, []);
    
    const handleSave = () => {
        if (user) {
            const newUser = {
                id: user.id,
                first_Name: firstName,
                last_Name: lastName
            };
        
            Api.updateUser(newUser)
                .then(() => {
                    setModalOpen(false);
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Fehler beim Aktualisieren des Benutzers:', error);
                });
        }
    };

    function doneClick() {
        history.push('/Aufgabe');
    }
    
    const showFilterWithText = () => {
      let formatierteBedingungen = '';
      if (!filterBedingungAusEigenerTabelle) {
        return (
          <>
            <Divider />
            <div style={{display: 'flex', alignItems: 'center'}} className='hover-underline' onClick={() => setPopupVisibility(true)}>
              <IoFilterOutline />
              <p>
                {selectedFilter && Object.keys(selectedFilter).length > 0 
                  ? Object.entries(selectedFilter)
                      .filter(([, values]) => values && values.length > 0)
                      .map(([key, values]) => {
                        let displayKey = key;
                        if (key === "subject") {
                          displayKey = t('subject');
                        }
                        if (key === "company") {
                          displayKey = t('company');
                        }
                        if (key === "status_Procent") {
                          displayKey = t('status');
                        }
                        if (key === "follow_up") {
                          displayKey = t('followUp');
                        }
                        if (displayKey === "start" || displayKey === "end") {
                          if (values[0]) {
                            const date = new Date(values[0]);
                            const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                            return `[${t(displayKey)}] ${formattedDate}`;
                          } else {
                            return;
                          }
                        } else {
                          return `[${t(displayKey)}] ${values.join(' & ')}`;
                        }
                      }).join(` ${t('and')} `).length > 0 ? Object.entries(selectedFilter)
                      .filter(([, values]) => values && values.length > 0)
                      .map(([key, values]) => {
                        let displayKey = key;
                        if (key === "status_Procent") {
                          displayKey = t('status');
                        }
                        if (key === "follow_up") {
                          displayKey = t('followUp');
                        }
                        if (displayKey === "start" || displayKey === "end") {
                          if (values[0]) {
                            const date = new Date(values[0]);
                            const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                            return `[${t(displayKey)}] ${formattedDate}`;
                          } else {
                            return;
                          }
                        } else {
                          return `[${t(displayKey)}] ${values.join(' & ')}`;
                        }
                      }).join(` ${t('and')} `) : t('createFilter')
                  : t('createFilter')}
              </p>
            </div>
          </>
        );
      } else if (Array.isArray(filterBedingungAusEigenerTabelle[0])) {
        formatierteBedingungen = filterBedingungAusEigenerTabelle.map((bedingung, index) => {
          if (Array.isArray(bedingung)) {
            let [object, operation, value] = bedingung;
            object = object === "status_Procent" ? "Status" : object;
            object = object === "subject" ? t('subject') : object;
            object = object === "company" ? t('company') : object;
            object = object === "follow_up" ? t('followUp') : object;
            object = object === "start" ? t('start') : object;
            object = object === "end" ? t('end') : object;
      
            // Datum formatieren, wenn object "start" oder "end" ist
            if (object === t('start') || object === t('end')) {
              const date = new Date(value);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              value = `${day}.${month}.${year}`;
            }
            return `[${object}] ${operation} ${value}`;
          } else if (typeof bedingung === 'string') {
            if (bedingung.toLowerCase() === 'and') {
              return index > 0 ? ' & ' : '';
            } else if (bedingung.toLowerCase() === 'or') {
              return index > 0 ? ' || ' : '';
            }
          }
          return '';
        }).join('');
      } else {
        let [object, operation, value] = filterBedingungAusEigenerTabelle;
        object = object === "status_Procent" ? "Status" : object;
        object = object === "subject" ? t('subject') : object;
        object = object === "company" ? t('company') : object;
        object = object === "follow_up" ? t('followUp') : object;
        object = object === "start" ? t('start') : object;
        object = object === "end" ? t('end') : object;
      
        // Datum formatieren, wenn object "start" oder "end" ist
        if (object === t('start') || object === t('end')) {
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          value = `${day}.${month}.${year}`;
        }
        formatierteBedingungen = `[${object}] ${operation} ${value}`;
      }
      
        return (
          <>
            <Divider />
            <div style={{display: 'flex', alignItems: 'center'}} className='hover-underline' onClick={() => setPopupVisibility(true)}>
              <IoFilterOutline />
              <p>{formatierteBedingungen}</p>
            </div>
          </>
        );
    };

    const allRoles = dataArray.reduce((acc, project) => {
        project.people.forEach(person => {
          if (!acc.includes(person.rolle.name)) {
            acc.push(person.rolle.name);
          }
        });
        return acc;
    }, []);

    return (

        <React.Fragment>

            
            
                  <div className={'content-block'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Button 
                        icon="plus"
                        className='myButton'
                        text={t('btnAdd')}
                        onClick={doneClick} 
                      />
                    </div>
                    <div style={{ textAlign: 'center', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: '100%' }}>
                      {t('title')}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <DropDownButton
                        text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                        icon="table"
                        items={Ansichten}
                        onSelectionChanged={onViewSelected} 
                      />
                    </div>
                  </div>
                 <div className={'content-block'}>        
                    <div className={'dx-card responsive-paddings'}> 
                      <AufgabenlistePC  
                        filter={filter} 
                        user={user}
                        dataArray={dataArray}
                        setDataArray={setDataArray}
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        aktualisieren={aktualisieren}
                        setAktualisieren={setAktualisieren}
                        showFilterWithText={showFilterWithText}
                        noDataMessage={noDataMessage}
                        setAktuelleFilterBedingung={setAktuelleFilterBedingung}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                        setFilterBedingungAusEigenerTabelle={setFilterBedingungAusEigenerTabelle}
                        setBedingungenFürPopup={setBedingungenFürPopup}
                        ausgewählteSpalten={ausgewählteSpalten}
                        setAusgewählteSpalten={setAusgewählteSpalten}
                      />
                        <div className="container-filtertext">
                            <div className="filtertext">
                                <p>{showFilterWithText()}</p>
                            </div>
                        </div>
                    </div>
            </div>

            <div className='alertContainer'>
                {isModalOpen && 
                    <Alert icon={<CiWarning size="8rem" />} title="Achtung!" color="red">
                       Um fortzufahren, müssen Sie Ihren Vor- und Nachnamen angeben.
                    </Alert>
                }
            </div>

            <Modal
                opened={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="lg"
                closeOnEscape
                centered
                withCloseButton={false}
                closeOnClickOutside={false}
            >

                <div className='modalHeader'>
                    <h5 className="modal-title">Profil vervollständigen</h5>
                </div>

                <Divider margins="xs" />

                <div className='form'>
                    <label className="label" style={{ marginTop: "5px" }}>Vorname</label>
                    <TextBox
                        label='Vorname'
                        value={firstName}
                        onValueChanged={(e) => setFirstName(e.value)}
                    />
                    <label className="label" style={{ marginTop: "1em" }}>Nachname</label>
                    <TextBox
                        label='Nachname'
                        value={lastName}
                        onValueChanged={(e) => setLastName(e.value)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className='saveButton' disabled={!firstName || !lastName} onClick={handleSave}>Speichern</Button>
                    </div>
                </div>
            </Modal>

            <FilterPopup 
              isPopupVisible={isPopupVisible} 
              setPopupVisibility={setPopupVisibility} 
              t={t} 
              allRoles={allRoles} 
              dataArray={dataArray} 
              setFilteredData={setFilteredData}
              filteredData={filteredData}
              aktuelleFilterBedingung={aktuelleFilterBedingung}
              filterObject={filterObject}
              bedingungenFürPopup={bedingungenFürPopup}
              filter={filter}
            />

        </React.Fragment>
    );
};
