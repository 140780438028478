import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import React from 'react';
import { ScrollView } from 'devextreme-react';
//import { Button } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { FcInvite } from "react-icons/fc";
import { LiaNetworkWiredSolid } from "react-icons/lia";
import EventBus from '../../components/Mitgliederliste-Mobil/EventBus'
import maprotaIcon from '../../logo192.png'
import { FaPersonCircleMinus } from "react-icons/fa6";

import { MdModeEdit, MdDelete } from 'react-icons/md';
import { FaUserXmark, FaPersonCirclePlus } from "react-icons/fa6";
import { useHistory } from 'react-router-dom';
import { Avatar } from '@mantine/core';
import i18n from "i18next";
import {useAuth} from '../../contexts/auth';
import { initial } from 'lodash';

const KontaktListeKomponente = ({
    setSelectedName, 
    setFormData, 
    open, 
    setModalData, 
    onConnect,    // Vernetzten
    oninvite,     // Einladung
    onWithdrawConnectionRequest ,           // Anfrage zurückziehen
    setIsModalOpen, 
    filteredData,
    
    Mitgliederliste,
}) => {

    const t = i18n.getFixedT(null, 'contacts');
    const tp = i18n.getFixedT(null, 'profile');
    
    const {user} = useAuth()

    const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
    const history = useHistory();
    const renderLogo = (data) => {
        //   console.log("KontaktlisteKomponente_renderLogo",data);
           const obj = data.data
           if (obj && obj.relationshipID === 0){ // MaProTa User
                    return <Button icon={maprotaIcon}    onClick={() => {
                                                                           history.push(`/profile/${obj.id}`)
                                                                        }
                            }/>
            } 
            else   if(obj && obj.relationshipID === 1){ // Verbunden
   
             
                return <Button icon={maprotaIcon} onClick={() => {
                                                                   history.push(`/profile/${obj.id}`)
                                                                 }
                }/>
              
               
            }
            else if (obj && obj.relationshipID === 3){ // Anfrage gesendet
               return <Button icon='/AnfrageGestellt.png'   onClick={() => {
                   history.push(`/profile/${obj.id}`)
               }
                   }/>
            }
            else if (obj && obj.relationshipID === 4){ // Anfrage erhalten
               return <Button icon='AnfrageEmpfangen.png'   onClick={() => {
                   history.push(`/profile/${obj.id}`)
               }
                   }/>
            }
          
            else {
              
                return <Button icon='card' onClick={() => {
                                                                history.push(`/profile/${obj.id}`)
                                                            }
               }/>
            }
       };


    function handleContactClick(obj) {
        console.log('Kontakt wurde geklickt', obj);
        const newSelectedName = obj;
        setSelectedName(newSelectedName); // Zustand aktualisieren
        EventBus.emit('selectedNameChanged', newSelectedName); // Ereignis auslösen mit emit
    }

   

    function IsBtnInvitation (obj) {
        // 2 == Kontaktliste
        if ( obj.relationshipID === 2 && obj.eMail && obj.reF_BP === EMPTY_GUID && IsOnlyContact(obj) === true ) {
            return true;
        } 
        else 
        {
            return false;
        } 
    }
    function IsBtnConnect (obj) {
         // 2 == Kontaktliste
        if (obj.reF_BP !== EMPTY_GUID && obj.relationshipID === 2 && IsOnlyContact(obj) === true) {
            return true;
        } 
        else 
        {
            return false;
        } 
    }

    function IsBtnEditDelete (obj) {
        // 2 == Kontaktliste
       if (obj.relationshipID === 2 ) {
           return true;
       } 
       else 
       {
           return false;
       } 
   }

    function IsBtnCancellationRequest (obj) {
        // 3 == Anfrage gesendet
       if ( obj.relationshipID === 3  ) {
           return true;
       } 
       else 
       {
           return false;
       } 
   }

   function IsBtnConnectMaProTaUser (obj) {
    // Verbinden i
    console.log("IsBtnConnectMaProTaUser",obj, user.id);
   if (  obj.relationshipID === 0 && obj.id !== user.id  ) {
       return true;
   } 
   else 
   {
       return false;
   } 
}

    function IsOnlyContact(obj){
        const foundItem = filteredData.find(item => item.id === obj.reF_BP && obj.relationshipID === 2);
       
       // console.log("foundItem",foundItem);
        if(foundItem !== undefined){
          
            console.log("foundItemID",obj.id, "REF", obj.reF_BP, "RID" , obj.relationshipID); 
            console.log("foundItem",foundItem);
            return false;
        }
        else{
            return true
        }
    }

   

    function handleProfileClick(id) {
        history.push(`/profile/${id}`);
    }
    const IconRender = () => <FaPersonCirclePlus size={20}  color="#4CBB17"   />;

    return (
        <React.Fragment>
        {/* // <ScrollView
        //     height={'53vh'}
        //     width={'auto'}
        //     direction="vertical"
        // > */}
        
            <section className="section" style={{paddingTop: '20px'}}>
            <div className="container">
                <div className="b-table">
                <div className="table-wrapper has-mobile-cards">
                    <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                        <th>{t('contactType')}</th>
                        <th> {/* Avatar */}</th>
                        
                        <th>{t('firstName')}</th>
                        <th>{t('lastName')}</th>
                        <th>{t('eMail')}</th>
                        <th>{t('positionName')}</th>
                        <th> {/* Buttons */}</th>
                        </tr>
                    </thead>
                    <tbody id='tbody'>
                        {filteredData.map((obj) => (
                        <tr key={obj.id} onClick={() => handleContactClick(obj)}>
                           
                           <td cellRender className="is-image-cell">
                            {renderLogo({ data: obj})}
                            </td>
                            <td>
                                {obj.relationshipID === 0 || obj.relationshipID === 1 || obj.relationshipID === 3 || obj.relationshipID === 4 ? (
                                    <div onClick={() => handleContactClick(obj)}>
                                    <Avatar radius="xl" src={obj.picture || null} className='profile-picture' />
                                    </div>
                                ) : null}
                            </td>
                            <td className='zelle' data-label={t('firstName')} style={{ cursor: Mitgliederliste ? 'pointer' : 'default' }}>{obj.first_Name}</td>
                            <td data-label={t('lastName')} style={{ cursor: Mitgliederliste ? 'pointer' : 'default' }}>{obj.last_Name}</td>
                            <td data-label={t('eMail')} style={{ cursor: Mitgliederliste ? 'pointer' : 'default' }}>{obj.eMail}</td>
                            <td data-label={t('positionName')} style={{ cursor: Mitgliederliste ? 'pointer' : 'default' }}>{obj.positionname}</td>
                            <td style={{ minWidth: '180px', maxWidth: '180px' }}>
                                <div className='positionKontaktButton'>
                                    
                                    {/* Kontakte bearbeiten und delete */}
                                        { IsBtnEditDelete(obj) ? (
                                           <div> <MdModeEdit size={20} color="#4CBB17" style={{marginRight: "0.5em", cursor: 'pointer'}}
                                                onClick={() => {
                                                setFormData({
                                                    id : obj.id,
                                                    firstName: obj.first_Name,
                                                    lastName: obj.last_Name,
                                                    position: obj.positionname,
                                                    email: obj.eMail,
                                                });
                                                open();
                                                }}
                                                />
                                                <MdDelete size={20} color="red" style={{cursor: 'pointer'}} onClick={() => { setModalData(obj); setIsModalOpen(true); }} />
                                            </div>
                                        ) : (
                                            <div style={{display: 'inline-block', width: '20px', marginRight: '0.5em'}}></div>
                                        )}
                                    {/* Kontakte mit Ref User vernetzen */}
                                        { IsBtnConnect(obj)   ? (
                                        <div> 
                                             <FaPersonCirclePlus size={20} color="#4CBB17" style={{marginLeft: "0.5em", cursor: 'pointer', verticalAlign: 'middle'}}
                                                      onClick={() => { onConnect(obj);  }}/>

                                            {/* <IconRender icon={IconRender}  text= {tp('btnUserAdd')}   onClick={() => { onConnect(obj);  }} */}
                                            {/* /> */}
                                            &nbsp;{tp('btnUserAdd')}
                                        </div>
                                        //</a>
                                    )
                                    : null }
                                    {/* MaProTa User Vernetzen */}
                                    { IsBtnConnectMaProTaUser(obj) ? (
                                        <div> 
                                             <FaPersonCirclePlus size={20} color="#4CBB17" style={{marginLeft: "0.5em", cursor: 'pointer', verticalAlign: 'middle'}}
                                                      onClick={() => { onConnect(obj);  }}/>

                                            {/* <IconRender icon={IconRender}  text= {tp('btnUserAdd')}   onClick={() => { onConnect(obj);  }} */}
                                            {/* /> */}
                                            &nbsp;{tp('btnUserAdd')}
                                        </div>
                                        //</a>
                                    )
                                    : null }
                                    {/* MaProTa User Verbindung löschen */}
                                        {obj.relationshipID === 1 ? (
                                            <FaUserXmark size={20} color="red" style={{cursor: 'pointer'}} onClick={() => { setModalData(obj); setIsModalOpen(true); }} />
                                        ) : ( null )
                                        } 
                                    {/* MaProTa User Einladung zurückziehen */}
                                        {IsBtnCancellationRequest(obj)  ? (
                                              <div> 
                                              <FaUserXmark size={20} color="red" style={{cursor: 'pointer'}} onClick={() => { onWithdrawConnectionRequest(obj); setIsModalOpen(true);  } } />
                                              &nbsp;{tp('btnUserCancellationRequest')} 
                                              </div>
                                               // <FaUserXmark size={20} color="red" style={{cursor: 'pointer'}} onClick={() => { setModalData(obj); setIsModalOpen(true); }} />
                                        ) : ( null )
                                        } 
                                    {/* Kontakt Einladung */}
                                        {IsBtnInvitation(obj)  ? (
                                        <>
                                            <FcInvite size={20} color="#4CBB17" style={{marginLeft: "0.5em", cursor: 'pointer', verticalAlign: 'middle'}}
                                                      onClick={() => { oninvite(obj);  }}/>
                                            <p>&nbsp;{t('btnEinladen')}</p>
                                        </>
                                    ) : null}
                                      
                                    
                                    
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </section>
        {/* </ScrollView> */}
        </React.Fragment>
    );
};
export default KontaktListeKomponente;